.container {
  margin-bottom: 20px;
}

.container:last-child {
  margin-bottom: 0;
}

.title {
  position: relative;
  padding: 13px 15px;
  cursor: pointer;
}

.icon {
  position: absolute;
  right: 13px;
  top: 15px;
}

.content {
  padding: 13px 15px;
  max-height: 60vh;
  overflow: scroll;
  overflow-x: hidden;
}
