.policyList {
  a {
    color: #fac83c;
    text-decoration: underline;
    word-break: break-word;
  }
  li {
    padding-left: 1em;
    text-indent: -1em;
  }
}
