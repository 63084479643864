.checkoutTermsButton {
  text-decoration: underline;
  cursor: pointer;
  vertical-align: top;
  display: inline-block;
}
.checkboxInput + span + label {
  position: relative;
  vertical-align: bottom;
}
.checkboxInput + span + label::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -19em;
  margin: auto;
  width: 25px;
  height: 25px;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
}
.checkboxInput:checked + span + label::before {
  border: 0;
  background-color: #fac83c;
}
.checkboxInput:checked + span + label::after {
  content: "";
  display: block;
  position: absolute;
  top: 3px;
  left: calc(9px - 19em);
  width: 7px;
  height: 11px;
  transform: rotate(40deg);
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}
.acceptTerms {
  display: inline-block;
  vertical-align: top;
}
