.modalFooter {
  display: flex;
  justify-content: center;
  border: none;
}
.checkoutTerms {
  border-radius: 10px;
  border: 1px solid #5a5a5a;
  overflow: hidden;
}
.checkoutTermsBlock {
  border-bottom: 1px solid #5a5a5a;
}
.checkoutTermsBlock:last-child {
  border-bottom-width: 0;
}
.checkoutTermsTitle {
  position: relative;
  background-color: #f2f2f2;
  padding: 13px 15px;
  cursor: pointer;
}
.checkoutTermsTitle i {
  position: absolute;
  right: 13px;
  top: 15px;
}
.checkoutTermsContent {
  padding: 13px 15px;
  border-top: 1px solid #5a5a5a;
  max-height: 30vh;
  overflow: scroll;
}
